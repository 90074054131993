
.dropZone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
  }
  .dropZone label {
    width: 100%;
    height: 100%;
    display: block;
  }
  .dropZone.dragging {
    border-color: #000;
  }
  
  button {
    margin-top: 16px;
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  .simpe-link {
    color: black;
  }
  .simpe-link:hover {
    text-decoration: none ;
  }


